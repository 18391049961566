import React from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { graphql } from 'gatsby';

import Gradient from 'src/components/Gradient';
import Biography from 'src/components/Biography';
import Container from 'src/components/Container';

import makoImage from 'src/images/mako.jpg';

const aboutAnimation = {
  hidden: {},
  visible: {
    transition: { duration: 0, staggerChildren: 0.1, when: 'beforeChildren' },
  },
};

const subSectionAnimation = {
  hidden: { opacity: 0, y: 20, skewY: -3 },
  visible: { opacity: 1, y: 0, skewY: 0 },
};

const About = ({ data }) => {
  const { biography, sections } = data.contentYaml;

  return (
    <Container>
      <Biography photoUrl={makoImage} richTextBody={biography} />
      <ExperienceWrapper
        initial="hidden"
        animate="visible"
        variants={aboutAnimation}
      >
        {sections.map((section, id) => (
          <Item key={id} title={section.title} sections={section.items} />
        ))}
      </ExperienceWrapper>
    </Container>
  );
};

const Item = ({ title, sections, ...props }) => (
  <section {...props}>
    <motion.h1
      variants={{
        hidden: { opacity: 0, x: -20 },
        visible: { opacity: 1, x: 0 },
      }}
    >
      {title}
    </motion.h1>
    {sections.map(
      ({ gradient, started, ended, isPresent, description }, id) => (
        <SubSection
          key={id}
          variants={subSectionAnimation}
          whileHover={{ x: 10 }}
        >
          <Tenure>
            {ended && <div>{ended}</div>}
            <FlexGradient fadeUp={isPresent} gradientSteps={gradient} />
            {started && <div>{started}</div>}
          </Tenure>
          <Content dangerouslySetInnerHTML={{ __html: description }} />
        </SubSection>
      ),
    )}
  </section>
);

const Content = styled('div')`
  margin-left: 0.75rem;
  margin-bottom: 1rem;

  h2 {
    font-weight: 400;
    font-size: 1.2rem;
    margin: 0;
    margin-bottom: 1rem;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0.25rem 0;
  }

  a {
    color: inherit;
  }
`;

const Tenure = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const FlexGradient = styled(Gradient)`
  flex: 1;
  margin: 0.25em 0;
  width: 4px;
`;

const ExperienceWrapper = styled(motion.div)`
  display: block;
  h1 {
    text-transform: uppercase;
    font-weight: 400;
  }
`;

const SubSection = styled(motion.div)`
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-gap: 0.8rem;
`;

export const query = graphql`
  query AboutQuery {
    contentYaml(sections: {}) {
      biography
      sections {
        items {
          description
          ended
          started
          gradient
          isPresent
        }
        title
      }
    }
  }
`;

export default About;
