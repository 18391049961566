import { css, keyframes } from '@emotion/core';
import styled from '@emotion/styled';

const getPosition = (p, value) => (p.fadeUp ? `0, ${value}` : value);
const getGradientAnimation = p => keyframes`
  0% {
    background-position-y: ${getPosition(p, 0)};
  }
  100% {
    background-position-y: ${getPosition(p, '1600px')};
  }
`;

const getGradient = p => `linear-gradient(0, ${p.gradientSteps.join(', ')})`;
const getPresentGradient = p => `
  linear-gradient(0, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 255))
`;

const getBackground = p =>
  p.fadeUp
    ? css`
        background-size: 100% 100%, 100% 800px;
        background-image: ${getPresentGradient(p)}, ${getGradient(p)};
      `
    : css`
        background-size: 100% 800px;
        background-image: ${getGradient(p)};
      `;

const Gradient = styled('div')`
  ${getBackground};
  animation: ${getGradientAnimation} 20s linear infinite;
  animation-delay: -${Math.ceil(Math.random() * 20)}s;
  border-radius: 2px;
`;

export default Gradient;
