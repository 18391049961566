import React from 'react';
import styled from '@emotion/styled';
import { motion, useViewportScroll, useTransform } from 'framer-motion';

const Photo = styled(({ shiftOffset, photoUrl, ...props }) => {
  const { scrollY } = useViewportScroll();
  const blueX = useTransform(scrollY, [0, 400], [shiftOffset, 0]);
  const greenX = useTransform(scrollY, [0, 400], [-1 * shiftOffset, -0]);

  return (
    <div {...props}>
      <PhotoPart photoUrl={photoUrl} color="#ff9086" opacity={1.0} />
      <PhotoPart
        photoUrl={photoUrl}
        color="#07b907"
        opacity={0.12}
        style={{ x: greenX }}
      />
      <PhotoPart
        photoUrl={photoUrl}
        color="#0bb1ff"
        opacity={0.15}
        style={{ x: blueX }}
      />
    </div>
  );
})`
  position: relative;
  border-radius: 50%;
  overflow: hidden;
`;

Photo.defaultProps = {
  shiftOffset: 70,
};

const PhotoPart = styled(motion.div)`
  background-image: url(${p => p.photoUrl});
  background-size: 100%;
  background-color: ${p => p.color};
  background-blend-mode: screen;
  opacity: ${p => p.opacity};
  border-radius: 50%;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
`;

export default Photo;
