import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { motion, useViewportScroll, useTransform } from 'framer-motion';

import Photo from 'src/components/Photo';
import { useIsMobile } from 'src/utils/useIsMobile';

const Biography = ({ photoUrl, richTextBody }) => (
  <Wrapper>
    <PhotoContainer photoUrl={photoUrl} />
    <BioWrapper>
      <div dangerouslySetInnerHTML={{ __html: richTextBody }} />
    </BioWrapper>
  </Wrapper>
);

const PhotoContainer = ({ photoUrl }) => {
  const photoRef = useRef(null);
  const { scrollY } = useViewportScroll();
  const y = useTransform(scrollY, [0, 220], [0, 220]);

  const isMobile = useIsMobile();

  if (isMobile) {
    return <StyeldPhoto photoUrl={photoUrl} shiftOffset={30} />;
  }

  return (
    <motion.div style={{ y }}>
      <StyeldPhoto photoUrl={photoUrl} ref={photoRef} />
    </motion.div>
  );
};

const StyeldPhoto = styled(Photo)`
  height: 350px;
  width: 350px;

  @media only screen and (max-device-width: 480px) {
    height: 200px;
    width: 200px;
    justify-self: center;
  }
`;

const Wrapper = styled('div')`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: max-content 1fr;

  @media only screen and (max-device-width: 480px) {
    grid-gap: 0.5rem;
    grid-template-rows: max-content 1fr;
    grid-template-columns: none;
    justify-content: center;
  }
`;

const WrappingBio = ({ children, ...props }) => {
  const isMobile = useIsMobile();
  const [isOpened, setIsOpened] = useState(false);

  if (!isMobile || isOpened) {
    return <div {...props}>{children}</div>;
  }

  return (
    <div {...props}>
      <ReducedBio>{children}</ReducedBio>
      {isMobile && !isOpened && (
        <ReadMoreButton onClick={() => setIsOpened(true)} />
      )}
    </div>
  );
};

const ReadMoreButton = styled('button')`
  display: flex;
  border: none;
  background: none;
  align-items: center;
  white-space: nowrap;
  width: 100%;
  padding: 1rem 0;
  font-family: 'Mrs Eaves OT';
  text-transform: uppercase;

  &:before,
  &:after {
    content: '';
    height: 1px;
    background: #999;
    width: 100%;
    margin: 0 0.5rem;
  }
`;

ReadMoreButton.defaultProps = {
  children: 'Read More',
};

const ReducedBio = styled('div')`
  p:not(:first-child) {
    display: none;
  }
`;

const BioWrapper = styled(WrappingBio)`
  line-height: 2rem;
  font-size: 1.05em;

  @media only screen and (max-device-width: 480px) {
    line-height: 1.9rem;
  }
`;

export default Biography;
